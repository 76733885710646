import "./SearchField.scss"

import React, { useState, useRef } from "react"
import classNames from "classnames"

import SearchIcon from "icons/glass"
import UserIcon from "icons/user"

export default function SearchField(props) {

  const [query, setQuery] = useState(props.query || "")
  const [focusedIndex, setFocusedIndex] = useState(-1)
  const [horizontalFocusedIndex, setHorizontalFocusedIndex] = useState(0)
  const [people, setPeople] = useState(null)
  const [books, setBooks] = useState(null)
  const inputRef = useRef()
  const listRef = useRef()
  const peopleRef = useRef()
  const timeoutRef = useRef()
  const abortControllerRef = useRef(new AbortController())

  function handleSubmit(event) {
    if (focusedIndex === 1 && people && people.length > 0) {
      const focusedPerson = peopleRef.current.children[horizontalFocusedIndex]
      if (focusedPerson && focusedPerson.getAttribute("href")) {
        event.preventDefault()
        window.location = focusedPerson.getAttribute("href")
      }
    } else if (focusedIndex > -1) {
      const focusedItem = listRef.current.children[focusedIndex]
      if (focusedItem) {
        const focusedLink = focusedItem.querySelector("a")
        if (focusedLink && focusedLink.getAttribute("href")) {
          event.preventDefault()
          window.location = focusedLink.getAttribute("href")
        }
      }
    }
  }

  function handleFocus() {

    if (query && !people && !books) fetchResults(query)

    if (query === props.query) {
      setTimeout(() => {
        inputRef.current.select()
      }, 10)
    }

    setFocusedIndex(query ? 0 : -1)

  }

  function handleKeyDown(event) {

    if (event.key === "ArrowDown") {
      event.preventDefault()
      const newIndex = focusedIndex >= listRef.current.children.length - 1 ? 0 : focusedIndex + 1
      setFocusedIndex(newIndex)
      listRef.current.children[newIndex].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start"
      })
    }

    if (event.key === "ArrowUp") {
      event.preventDefault()
      const newIndex = focusedIndex > 0 ? focusedIndex - 1 : listRef.current.children.length - 1
      setFocusedIndex(newIndex)
      listRef.current.children[newIndex].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start"
      })
    }

    if (event.key === "ArrowLeft" && people && people.length > 0 && focusedIndex === 1) {
      event.preventDefault()
      setHorizontalFocusedIndex(horizontalFocusedIndex > 0 ? horizontalFocusedIndex - 1 : peopleRef.current.children.length - 1)
    }

    if (event.key === "ArrowRight" && people && people.length > 0 && focusedIndex === 1) {
      event.preventDefault()
      setHorizontalFocusedIndex(horizontalFocusedIndex >= peopleRef.current.children.length - 1 ? 0 : horizontalFocusedIndex + 1)
    }

  }

  function handleChange(event) {
    if (event.target.value) setFocusedIndex(0)
    setQuery(event.target.value)

    abortControllerRef.current.abort()
    abortControllerRef.current = new AbortController()

    clearTimeout(timeoutRef.current)
    setPeople(null)
    setBooks(null)
    if (event.target.value) {
      timeoutRef.current = setTimeout(() => fetchResults(event.target.value), 500)
    }
  }

  async function fetchResults(query) {
    try {
      const response = await fetch(`/books?q=${query}`, {
        method: "GET",
        signal: abortControllerRef.current.signal,
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": props.csrfToken,
          "Accept": "application/json"
        }
      })

      if (response.status === 401) {
        location.href = `/auth?dest=/books?q=${query}`
        return
      }

      const data = await response.json()

      if (data.success) {
        setPeople(data.people)
        setBooks(data.books)
      }
    } catch (e) {
      console.log(e)
    }
  }

  function renderBook(book, index) {
    return (
      <li
        key={book.googleVolumeId}
        className={classNames({ focus: focusedIndex === index })}
        onMouseOver={() => setFocusedIndex(index)}
      >
        <a href={`${book.link}?q=${query}`}>
          <figure>
            { book.imageUrl ? <img src={book.imageUrl} /> : <div className="placeholder" /> }
          </figure>
          <div className="details">
            <h3>{book.title}</h3>
            <div className="meta">
              { book.authors && <span className="authors">{book.authors}</span> }
              { book.publishedYear && <span className="published">{book.publishedYear}</span> }
            </div>
          </div>
        </a>
      </li>
    )
  }

  return (
    <form
      className={classNames("SearchField", { "dropdown-empty": !query && (!props.recentBooks || props.recentBooks.length === 0) })}
      method="get"
      action="/books"
      onSubmit={handleSubmit}
    >

      <SearchIcon />
      <input
        type="search"
        name="q"
        placeholder="Search for books or people..."
        autoComplete="off"
        spellCheck={false}
        value={query}
        ref={inputRef}
        onChange={handleChange}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
      />

      <ul className="dropdown" ref={listRef}>
        {(() => {
          if (!query) {
            if (props.recentBooks && props.recentBooks.length > 0) {
              return props.recentBooks.map(renderBook)
            }
          } else {
            return <>

              <li
                className={classNames("default-search", { focus: focusedIndex === 0 })}
                onMouseOver={() => setFocusedIndex(0)}
              >
                <a href={`/books?q=${query}`}>
                  Search for “<span>{query}</span>”
                </a>
              </li>

              {(() => {
                if (people && people.length > 0) {
                  return (
                    <li className="people" ref={peopleRef} onMouseOver={() => setFocusedIndex(1)}>
                      {people.map((person, index) => (
                        <a
                          key={person.username}
                          href={`${person.link}?q=${query}`}
                          className={classNames({ focus: focusedIndex === 1 && horizontalFocusedIndex === index })}
                          onMouseOver={() => setHorizontalFocusedIndex(index)}
                        >
                          <figure className="person-picture small" style={{ backgroundImage: person.imageUrl ? `url(${person.imageUrl})` : null }}>
                            { !person.imageUrl && <UserIcon /> }
                          </figure>
                          {person.name}
                        </a>
                      ))}
                    </li>
                  )
                }
              })()}

              {books && books.map((book, index) => renderBook(book, people && people.length > 0 ? index + 2 : index + 1))}

            </>
          }
        })()}
      </ul>

    </form>
  )

}
