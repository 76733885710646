import "./Select.scss"

import React, { useState } from "react"
import classNames from "classnames"

export default function Select(props) {

  const { options, className, ...otherProps } = props

  function getOptionText() {
    if (options instanceof Array) {
      return options.filter(option => option[1] === props.value)[0][0]
    } else if (options instanceof Object) {
      return Object.keys(options).reduce((allOptions, optgroupLabel) => allOptions.concat(options[optgroupLabel]), []).filter(option => option[1] === props.value)[0][0]
    }
  }

  return (
    <div className={classNames("Select", "select-input", className)}>
      <span>{getOptionText()}</span>
      <select {...otherProps}>
        {(() => {
          if (options instanceof Array) {
            return options.map((option, index) => (
              <option key={ option[1] ? option[1] : index } value={option[1]}>{option[0]}</option>
            ))
          } else if (options instanceof Object) {
            return Object.keys(options).map(optgroupLabel => (
              <optgroup key={optgroupLabel} label={optgroupLabel.charAt(0).toUpperCase() + optgroupLabel.slice(1)}>
                {
                  options[optgroupLabel].map((option, index) => (
                    <option key={ option[1] ? option[1] : index } value={option[1]} {...option[2]}>{option[0]}</option>
                  ))
                }
              </optgroup>
            ))
          }
        })()}
      </select>
    </div>
  )

}
