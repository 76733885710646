import "./FollowButton.scss"

import React, { PureComponent } from "react"
import classNames from "classnames"

export default class FollowButton extends PureComponent {

  state = {
    followState: this.props.followState,
    loading: false
  }

  render() {
    const { followState, loading } = this.state
    return (
      <button
        className={classNames("FollowButton", "button", {
          primary: !followState || followState === "unfollowed",
          secondary: followState === "following" || followState === "requested",
          loading
        })}
        type="button"
        onClick={() => this.toggleFollowState()}
      >
        {this.getButtonText()}
      </button>
    )
  }

  getButtonText() {
    const { followState } = this.state
    switch (followState) {

      case "following":
        return "Unfollow"

      case "requested":
        return "Requested"

      case "unfollowed":
      case null:
      default:
        return "Follow"

    }
  }

  async toggleFollowState() {

    const { username, csrfToken } = this.props
    const startTime = new Date().getTime()

    this.setState({
      loading: true
    })

    const response = await fetch(`/people/${username}/follow`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      }
    })

    if (response.status === 401) {
      location.href = `/auth?dest=/people/${username}`
      return
    }

    const data = await response.json()

    const timeElapsed = new Date().getTime() - startTime
    setTimeout(() => {
      this.setState({
        followState: data.followState,
        loading: false
      })
    }, timeElapsed < 300 ? 300 - timeElapsed : 0)

  }

}
