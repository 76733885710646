import "./PersonListEditor.scss"

import React, { useState, useRef, useEffect } from "react"
import classNames from "classnames"
import { ReactSortable } from "react-sortablejs"

import usePrevious from "javascript/use-previous"

import Select from "components/Select"

import SortIcon from "icons/sort"
import PencilIcon from "icons/pencil"
import HeartOutlineIcon from "icons/heart-outline"
import HeartIcon from "icons/heart"
import EyeIcon from "icons/eye"
import EyeCrossIcon from "icons/eye-cross"
import SearchIcon from "icons/glass"

export default function PersonListEditor(props) {

  const [editing, setEditing] = useState(false)
  const [sortBy, setSortBy] = useState(props.sortBy)
  const [visibility, setVisibility] = useState(props.visibility)
  const [name, setName] = useState(props.name)
  const [books, setBooks] = useState(props.books)
  const previousEditing = usePrevious(editing)
  const editFormRef = useRef()
  const listNameRef = useRef()

  useEffect(() => {
    if (editing && !previousEditing) {
      listNameRef.current.focus()
      listNameRef.current.select()
    }
  })

  function handleSortByChange(event) {
    setSortBy(event.target.value)
    setTimeout(() => {
      event.target.form.submit()
    }, 100)
  }

  function handleEditClick() {
    setEditing(true)
  }

  function handleVisibilityChange({ target: { value } }) {
    setVisibility(value)
  }

  function handleNameChange({ target: { value } }) {
    setName(value)
  }

  function handleSaveClick() {
    editFormRef.current.submit()
  }

  function handleCancelClick() {
    setEditing(false)
    setName(props.name)
  }

  async function handleSort() {
    const response = await fetch(`/person_lists/${props.listId}/reorder_books`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.csrfToken
      },
      body: JSON.stringify({
        order: books.map(book => book.id)
      })
    })

    if (response.status === 401) {
      location.href=`/auth?dest=/library/${props.listId}`
    }
  }

  function renderBooks() {
    return books.map(book => (
      <div key={book.id} className="book-cover-link">
        <a href={book.link} title={book.titleAndAuthor}>
          {(() => {
            if (book.imageUrl) {
              return <img src={book.imageUrl} />
            } else {
              return <div className="placeholder" />
            }
          })()}
        </a>
        <label>
          <input type="checkbox" className="like-toggle" data-google-volume-id={book.googleVolumeId} defaultChecked={book.isLiked} />
          <HeartOutlineIcon className="off" />
          <HeartIcon className="on" />
        </label>
      </div>
    ))
  }

  return (
    <div className={classNames("PersonListEditor", { editing })}>

      <header>

        <div className="heading">

          {(() => {
            if (editing) {
              return (
                <form method="post" ref={editFormRef}>
                  <input
                    ref={listNameRef}
                    type="text"
                    name="person_list[name]"
                    className="text-input size-large"
                    placeholder={"List name"}
                    value={name}
                    onChange={handleNameChange}
                    autoComplete="off"
                  />
                  <Select
                    name="person_list[visibility]"
                    className="size-small"
                    options={[["Public", "public"], ["Private", "private"]]}
                    value={visibility}
                    onChange={handleVisibilityChange}
                  />
                  <input type="hidden" name="_method" value="patch" />
                  <input type="hidden" name="authenticity_token" value={props.csrfToken} />
                </form>
              )
            } else {
              return <>
                <nav>
                  <a href="/library">Library</a> /
                </nav>
                <h1>{props.name}</h1>
              </>
            }
          })()}

        </div>

        <nav className="actions">

          {(() => {
            if (editing) {
              return <>
                <button className="button secondary frameless large" onClick={handleCancelClick}>Cancel</button>
                <button className="button primary large" onClick={handleSaveClick}>Save</button>
              </>
            } else {
              return <>

                {(() => {
                  if (visibility === "public") {
                    return <div className="visibility-icon eye-icon" title="This list is currently public. Anyone who can see your profile can see it."><EyeIcon /></div>
                  } else if (visibility === "private") {
                    return <div className="visibility-icon eye-cross-icon" title="This list is currently private. Only you can see it."><EyeCrossIcon /></div>
                  }
                })()}

                <SortIcon className="sort-icon" />

                <form method="post">
                  <input type="hidden" name="_method" value="patch" />
                  <input type="hidden" name="authenticity_token" value={props.csrfToken} />
                  <Select
                    className="scale-small frameless"
                    name="person_list[sort_by]"
                    options={props.sortByOptions}
                    value={sortBy}
                    onChange={handleSortByChange}
                  />
                </form>

                <button className="button secondary" onClick={handleEditClick}><PencilIcon />Edit</button>

              </>
            }
          })()}

        </nav>

      </header>

      {(() => {
        if (props.books.length > 0) {
          if (sortBy === "index") {
            return (
              <ReactSortable
                list={books}
                setList={setBooks}
                className="book-grid sortable"
                animation={300}
                onSort={handleSort}
                supportPointer={false}
              >
                {renderBooks()}
              </ReactSortable>
            )
          } else {
            return (
              <section className="book-grid">
                {renderBooks()}
              </section>
            )
          }
        } else {
          return (
            <section className="book-grid empty">
              <p>This list doesn’t have any books yet.</p>
              <form method="get" action="/books">
                <SearchIcon />
                <input
                  name="q"
                  type="search"
                  className="text-input scale-large"
                  placeholder="Find something to add..."
                  autoFocus={true}
                  autoComplete="off"
                />
                <input
                  type="submit"
                  value="Search"
                  className="button primary xlarge"
                />
              </form>
            </section>
          )
        }
      })()}

    </div>
  )

}
