import "./FileInput.scss"

import React, { PureComponent } from "react"

import UploadIcon from "icons/upload"

export default class FileInput extends PureComponent {

  componentDidMount() {
    const { id } = this.props
    this.fileInput = document.querySelector(`#${id}`)
    this.fileInput.addEventListener('change', this.handleChange)
  }

  render() {
    const { id } = this.props
    return (
      <label htmlFor={id} className="FileInput button secondary">
        <UploadIcon />{ this.getFilename() || "Choose new picture" }
      </label>
    )
  }

  handleChange = () => {
    this.forceUpdate()
  }

  getFilename = () => {
    if (!this.fileInput) return
    if (!this.fileInput.files) return
    if (!this.fileInput.files[0]) return
    return this.fileInput.files[0].name
  }

}
