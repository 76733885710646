import "./PersonListSelector.scss"

import React, { useState, useRef, useEffect } from "react"
import classNames from "classnames"

import CaretIcon from "icons/caret"
import PlusIcon from "icons/plus"
import CheckIcon from "icons/check"

export default function PersonListSelector(props) {

  const [personLists, setPersonLists] = useState(props.personLists)
  const [personListsOrder, setPersonListsOrder] = useState(props.personListsOrder)
  const [loading, setLoading] = useState(false)
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const [newListToggle, setNewListToggle] = useState(false)
  const [newListName, setNewListName] = useState("")

  const containerRef = useRef()
  const dropdownRef = useRef()
  const newListNameInputRef = useRef()

  function handleWindowClick() {
    if (dropdownVisible) {
      toggleDropdown(false)
    }
  }

  function toggleDropdown(visible) {
    setDropdownVisible(visible === true || visible === false ? visible : !dropdownVisible)
    setNewListToggle(false)
    setNewListName("")
  }

  function handleClick(event) {
    event.stopPropagation()
  }

  async function setPersonListState(id, present) {

    const startTime = new Date().getTime()

    setLoading(true)

    const response = await fetch(`/person_list_books/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.csrfToken
      },
      body: JSON.stringify({
        google_volume_id: props.googleVolumeId,
        present
      })
    })

    if (response.status === 401) {
      location.href = `/auth?dest=/books/${props.googleVolumeId}`
      return
    }

    const data = await response.json()

    const timeElapsed = new Date().getTime() - startTime
    setTimeout(() => {
      if (data.success) {
        setLoading(false)
        setPersonLists(data.person_lists)
      } else {
        setLoading(false)
      }
    }, timeElapsed < 300 ? 300 - timeElapsed : 0)

  }

  async function togglePersonList(id) {
    setPersonListState(id, !personLists[id].present)
  }

  function handleSampleListClick() {
    location.href = `/auth?dest=books/${props.googleVolumeId}`
  }

  async function handleNewListSubmit(event) {
    event.preventDefault()

    if (!newListName.trim()) return

    const response = await fetch(`/person_lists/create_with_book`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.csrfToken
      },
      body: JSON.stringify({
        google_volume_id: props.googleVolumeId,
        person_list: {
          name: newListName
        }
      })
    })

    if (response.status === 401) {
      location.href = `/auth?dest=/books/${props.googleVolumeId}`
      return
    }

    const data = await response.json()

    setPersonLists(data.person_lists)
    setPersonListsOrder(data.person_lists_order)
    setNewListToggle(false)
    setNewListName("")
  }

  useEffect(() => {

    window.addEventListener("click", handleWindowClick)

    if (dropdownRef.current.offsetWidth > containerRef.current.offsetWidth) {
      containerRef.current.classList.toggle("oversized-dropdown", true)
    }

    if (newListToggle && newListNameInputRef.current && newListNameInputRef.current.focus) newListNameInputRef.current.focus()

    return () => {
      window.removeEventListener("click", handleWindowClick)
    }

  })

  const listCount = personLists ? personListsOrder.filter(id => personLists[id].present).length : 0

  return (
    <div
      className={classNames("PersonListSelector", {
        loading,
        "dropdown-visible": dropdownVisible,
        "new-list-toggle": newListToggle,
        [`align-${props.align}`]: props.align
      })}
      onClick={handleClick}
      ref={containerRef}
    >

      <button className={classNames("dropdown-toggle", "button", { primary: listCount === 0, secondary: listCount > 0 })} onClick={e => toggleDropdown(e)}>
        {listCount === 0 && <><PlusIcon className="plus" />Library</>}
        {listCount > 0 && <><CheckIcon className="check" />{listCount} list{ listCount > 1 && "s" }</>}
        <CaretIcon className="caret" />
      </button>

      <div className="dropdown" ref={dropdownRef}>
        {(() => {
          if (!personListsOrder || !personLists) {
            return ["Currently reading", "Want to read", "Already read"].map((listName, index) => (
              <label key={index}>
                <input type="checkbox" onChange={handleSampleListClick} />
                <PlusIcon className="plus" />
                {listName}
              </label>
            ))
          } else {
            return <>

              {personListsOrder.map(id => {
                return (
                  <label key={id}>
                    <input
                      type="checkbox"
                      checked={personLists[id].present}
                      onChange={() => togglePersonList(id)}
                    />
                    { !personLists[id].present && <PlusIcon className="plus" /> }
                    { personLists[id].present && <CheckIcon className="check" /> }
                    {personLists[id].name}
                  </label>
                )
              })}

              <form onSubmit={handleNewListSubmit} className="new-list">

                <label>
                  <PlusIcon className="plus" />
                  {(() => {
                    if (newListToggle) {
                      return (
                        <input
                          ref={newListNameInputRef}
                          type="text"
                          className="text-input scale-small size-small"
                          placeholder="Name of list"
                          maxLength={100}
                          value={newListName}
                          onChange={event => setNewListName(event.target.value)}
                          autoComplete="off"
                        />
                      )
                    } else {
                      return (
                        <input
                          type="button"
                          value="New list"
                          onClick={() => setNewListToggle(true)}
                        />
                      )
                    }
                  })()}
                </label>

                {(() => {
                  if (newListToggle) {
                    return (
                      <button className="button primary">Save</button>
                    )
                  }
                })()}

              </form>

            </>
          }
        })()}
      </div>

    </div>
  )

}
