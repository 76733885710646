import "./PersonListManager.scss"

import React, { useState, useEffect, useRef } from "react"
import classNames from "classnames"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"

import PencilIcon from "icons/pencil"
import TrashIcon from "icons/trash"
import HandleIcon from "icons/handle"
import HeartOutlineIcon from "icons/heart-outline"
import HeartIcon from "icons/heart"

export default function PersonListManager(props) {

  const [listOrder, updateListOrder] = useState(props.listOrder)
  const [lists, updateLists] = useState(props.lists)
  const listNameInputRef = useRef()

  useEffect(() => {
    listOrder.forEach(listId => {
      if (typeof lists[listId].pendingName === "string" && lists[listId].shouldSelect) {
        listNameInputRef.current.select()
        updateLists({
          ...lists,
          [listId]: {
            ...lists[listId],
            shouldSelect: false
          }
        })
      }
    })
  })

  function handleRenameClick(listId) {
    updateLists({
      ...lists,
      [listId]: {
        ...lists[listId],
        pendingName: lists[listId].name,
        shouldSelect: true
      }
    })
  }

  function handleListNameChange(listId, { target: { value } }) {
    updateLists({
      ...lists,
      [listId]: {
        ...lists[listId],
        pendingName: value
      }
    })
  }

  function handleListNameKeyPress(event) {
    if (event.key === "Enter" || event.key === "Escape") {
      listNameInputRef.current.blur()
    }
  }

  async function handleListNameFormSubmit(listId, event) {

    event.preventDefault()

    const name = lists[listId].pendingName.trim()
    if (!name) return

    updateLists({
      ...lists,
      [listId]: {
        ...lists[listId],
        name,
        pendingName: null,
      }
    })

    const response = await fetch(`/person_lists/${listId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.csrfToken,
        "Accept": "application/json"
      },
      body: JSON.stringify({
        person_list: {
          name
        }
      })
    })

    if (response.status === 401) {
      location.href = `/auth?dest=/library`
    }

  }

  function handleListNameFormReset(listId, event) {
    event.preventDefault();
    updateLists({
      ...lists,
      [listId]: {
        ...lists[listId],
        pendingName: null
      }
    })
  }

  async function handleDeleteClick(listId) {

    if (!confirm("Are you sure you want to delete this list?")) return

    const { [listId]: listToDelete, ...otherLists } = lists
    updateListOrder(listOrder.filter(originalListId => originalListId !== listId))
    updateLists(otherLists)

    const response = await fetch(`/person_lists/${listId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.csrfToken
      }
    })

    if (response.status === 401) {
      location.href = `/auth?dest=/library`
    }

  }

  async function handleDragEnd(result) {
    if (!result.destination) return

    const [movedListId] = listOrder.splice(result.source.index, 1)
    listOrder.splice(result.destination.index, 0, movedListId)
    updateListOrder(listOrder)

    const response = await fetch(`/person_lists/reorder`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": props.csrfToken
      },
      body: JSON.stringify({
        list_order: listOrder
      })
    })

    if (response.status === 401) {
      location.href = `/auth?dest=/library`
    }
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="lists">
        {provided => (
          <div
            className="PersonListManager"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {listOrder.map((listId, index) => (
              <Draggable key={listId} draggableId={listId.toString()} index={index}>
                {(provided, snapshot) => (
                  <section
                    className={classNames("list", {
                      empty: lists[listId].books.length === 0,
                      dragging: snapshot.isDragging,
                      renaming: typeof lists[listId].pendingName === "string"
                    })}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                  >

                    <div className="heading">
                      <div className="drag-handle" {...provided.dragHandleProps}>
                        <HandleIcon />
                      </div>
                      {(() => {
                        if (typeof lists[listId].pendingName === "string") {
                          return (
                            <form
                              className="rename-form"
                              onSubmit={event => handleListNameFormSubmit(listId, event)}
                              onReset={event => handleListNameFormReset(listId, event)}
                            >

                              <input
                                type="text"
                                className="text-input size-large scale-small"
                                value={lists[listId].pendingName}
                                onChange={event => handleListNameChange(listId, event)}
                                autoFocus={true}
                                ref={listNameInputRef}
                                onKeyUp={handleListNameKeyPress}
                                maxLength={100}
                                autoComplete="off"
                              />

                              <input type="submit" value="Save" className="button primary" />
                              <input type="reset" value="Cancel" className="button secondary frameless" />

                            </form>
                          )
                        } else {
                          return <>
                            <h3 className="bold"><a href={lists[listId].link}>{lists[listId].name}</a> <span className="count">{lists[listId].bookCount}</span></h3>
                            <button className="button secondary tiny frameless rename" onClick={() => handleRenameClick(listId)}><PencilIcon className="pencil" />Rename</button>
                            <button className="button secondary tiny frameless delete" onClick={() => handleDeleteClick(listId)}><TrashIcon className="trash" />Delete</button>
                          </>
                        }
                      })()}
                    </div>

                    {(() => {
                      if (lists[listId].books.length > 0) {
                        return (
                          <div className="horizontal-book-list" style={{ marginTop: "24px" }}>
                            {lists[listId].books.map(book => (
                              <div key={book.id} className="book-cover-link">
                                <a href={book.link} title={book.titleAndAuthor}>
                                  {(() => {
                                    if (book.imageUrl) {
                                      return <img src={book.imageUrl} />
                                    } else {
                                      return <div className="placeholder" />
                                    }
                                  })()}
                                </a>
                                <label>
                                  <input type="checkbox" className="like-toggle" data-google-volume-id={book.googleVolumeId} defaultChecked={book.isLiked} />
                                  <HeartOutlineIcon className="off" />
                                  <HeartIcon className="on" />
                                </label>
                              </div>
                            ))}
                          </div>
                        )
                      } else {
                        return <p>This list doesn’t have any books yet.</p>
                      }
                    })()}

                  </section>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )

}
