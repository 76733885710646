import "./FollowRequestNotification.scss"

import React, { PureComponent } from "react"
import classNames from "classnames"

export default class FollowRequestNotification extends PureComponent {

  state = {
    loading: false,
    followState: this.props.followState
  }

  render() {
    const { followerName, followerPath, when, whenDate } = this.props
    const { loading, followState } = this.state
    return (
      <section
        className={classNames("FollowRequestNotification", "notification", {
          loading
        })}
      >
        {(() => {
          if (followState === "unfollowed") {
            return (
              <div>
                <p>You ignored a follow request from <a href={followerPath}>{followerName}</a>.</p>
                <div className="when">{when}</div>
              </div>
            )
          } else if (followState === "following") {
            return (
              <div>
                <p><a href={followerPath}>{followerName}</a> is now following you!</p>
                <div className="when">{when}</div>
              </div>
            )
          } else {
            return <>
              <div>
                <p><a href={followerPath}>{followerName}</a> wants to follow you and see your library.</p>
                <div className="when" title={whenDate}>{when}</div>
              </div>
              <div className="actions">
                <button className="button small secondary" onClick={() => this.ignoreFollowRequest()}>Ignore</button>
                <button className="button small primary" onClick={() => this.allowFollowRequest()}>Allow</button>
              </div>
            </>
          }
        })()}
      </section>
    )
  }

  async ignoreFollowRequest() {

    const { followId, followerPath, csrfToken } = this.props
    const startTime = new Date().getTime()

    this.setState({
      loading: true
    })

    const response = await fetch(`/follows/${followId}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      }
    })

    if (response.status === 401) {
      return location.href = `/auth?dest=${followerPath}`
    }

    if (!response.ok) return

    const data = await response.json()

    const timeElapsed = new Date().getTime() - startTime
    setTimeout(() => {
      this.setState({
        loading: false,
        followState: data.followState
      })
    }, timeElapsed < 300 ? 300 - timeElapsed : 0)

  }

  async allowFollowRequest() {

    const { followId, followerPath, csrfToken } = this.props
    const startTime = new Date().getTime()

    this.setState({
      loading: true
    })

    const response = await fetch(`/follows/${followId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      }
    })

    if (response.status === 401) {
      return location.href = `/auth?dest=${followerPath}`
    }

    if (!response.ok) return

    const data = await response.json()

    const timeElapsed = new Date().getTime() - startTime
    setTimeout(() => {
      this.setState({
        loading: false,
        followState: data.followState
      })
    }, timeElapsed < 300 ? 300 - timeElapsed : 0)

  }

}
