// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Support component names relative to this directory:
var componentRequireContext = require.context("../../components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);

import "stylesheets/packs/base"

window.addEventListener("DOMContentLoaded", () => {

  const csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content")

  const accountMenu = document.querySelector("#account-menu")
  const notificationsMenu = document.querySelector("#notifications-menu")

  if (accountMenu && notificationsMenu) {
    const accountMenuToggle = accountMenu.querySelector(".menu-toggle")
    const notificationsMenuToggle = notificationsMenu.querySelector(".menu-toggle")

    window.addEventListener("click", event => {
      if (accountMenuToggle && event.target !== accountMenu && !accountMenu.contains(event.target)) accountMenuToggle.checked = false
      if (notificationsMenuToggle && event.target !== notificationsMenu && !notificationsMenu.contains(event.target)) notificationsMenuToggle.checked = false
    })

    const unreadCount = notificationsMenu.querySelector(".unread-count")
    if (unreadCount) {
      let hasMarkedRead = false
      const pageLoadTimestamp = Math.round(new Date().getTime() / 1000)
      notificationsMenuToggle.addEventListener("change", async event => {
        if (!hasMarkedRead && event.target.checked) {
          hasMarkedRead = true
          await fetch(`/notifications/mark-as-read/${pageLoadTimestamp}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "X-CSRF-Token": csrfToken
            }
          })

          unreadCount.style.display = "none"
        }
      })
    }
  }

  document.querySelectorAll(".select-input:not(.Select)").forEach(selectInput => {

    const text = document.createElement("span")
    selectInput.prepend(text)

    const selectElement = selectInput.querySelector("select")
    const handleChange = () => text.innerText = selectElement.options[selectElement.selectedIndex].innerText
    selectElement.addEventListener("change", handleChange)
    handleChange()

  })

  document.querySelector("body").addEventListener("change", event => {
    if (event.target.classList.contains("like-toggle")) {

      if (event.target.dataset.signedIn === "false") {
        event.preventDefault()
        location.href = `/auth?dest=${location.pathname}`
        return
      }

      fetch(`/like/${event.target.dataset.googleVolumeId}`, {
        method: event.target.checked ? "POST" : "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "X-CSRF-Token": csrfToken
        }
      }).then(response => {
        if (response.status === 401) {
          location.href = `/auth?dest=${location.pathname}`
        }
      })

    }
  })

})
